<template>
  <div>
     <loading
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="true"
    ></loading>
    <b-modal
      title="Acepta para continuar"
      v-model="flagTerms"
      hide-header-close
      hide-footer
      no-close-on-backdrop
    >
      <b-row>        
        <b-col lg="12">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="gridCheck"
              v-model="termsAndConditions"
            />
            <label class="form-check-label" for="gridCheck">
              Acepto los
              <a v-on:click="goTo('termsProvider')" v-if="isProvider">
                <span class="text-link-color">Términos y Condiciones para proveedores</span>
              </a>
              <a v-on:click="goTo('terms')" v-else>
                <span class="text-link-color">Términos y Condiciones</span>
              </a>
              de Go Guau
            </label>
          </div>
        </b-col>
        <hr />
        <b-col lg="12" class="text-center">
          <b-button 
            class="s-bg s-bg-text" 
            :disabled="!termsAndConditions"
            @click="submit()"
          >
            Aceptar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
export default {
  name: "termsAcceptance",
  props: [ 'userProfile'],
  components: {Loading},
  data() {
    return {
      flagTerms: true,
      termsAndConditions: true,   
      isProvider: false,
      loading: false,
    };
  },
  created () {
    // console.log(this.userProfile)
    if(this.userProfile.type == 'client') {
      this.isProvider = false
    } else {
      //  type = provider
      this.isProvider = true
    }
  },
  methods: {
    goTo(to) {
      if (to == "terms") {
        let route = this.$router.resolve({ path: "/pages/terms" });
        window.open(route.href, "_blank");
      } else if (to == "termsProvider") {
        let route = this.$router.resolve({ path: "/pages/termsprovider" });
        window.open(route.href, "_blank");
      }
    },
    submit() {      
      const data = {
        userId: this.userProfile.id,
        termsStateId: 1,
      }
      this.loading = true
      if(this.userProfile.type == 'client') {
        this.updateClient(data)
      } else {
        this.updateProvider(data)
      }
    },
    updateClient(data) {
      // console.log('updateClientTerms')
      let updateClientTerms = this.$firebase.functions().httpsCallable('updateClientTerms');
      updateClientTerms(data).then((response) => {
        // console.log(response.data)
        this.fetchUser()
      }).catch((error) => {
        console.log(error)
      }).finally(() => this.loading = false)
    },
    updateProvider(data){
      // console.log('updateProviderTerms')
      let updateProviderTerms = this.$firebase.functions().httpsCallable('updateProviderTerms');
      updateProviderTerms(data).then((response) => {
        // console.log(response.data)
        this.fetchUser()
      }).catch((error) => {
        console.log(error)
      }).finally(() => this.loading = false)
    },
    fetchUser() {
      const currentUser = this.$firebase.auth().currentUser
      // console.log(currentUser)
      const userId = currentUser != null ? currentUser.uid : "";
      this.$store.dispatch('fetchUser', {'userId': userId}).then((response) => {          
        let userData = response;          
        // console.log(userData)
        if(userData){
          if(userData.flag_terminos.id == 1)
            this.flagTerms = false
          this.$store.commit('defUserDbId', userData.id);
          this.$store.commit('defUserProfile', JSON.stringify(userData));
          this.$store.commit('defUserType', userData.type);
        }
        // this.loading = false
      }).catch((e) => {               
        // this.loading = false
      })
    }
  }
};
</script>