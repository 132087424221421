<template>
    <div>
        <loading :active.sync="loading"
            :can-cancel="false"
            background-color="#fff"
            :is-full-page="false">
        </loading>
        <TermsAcceptance              
            v-if="userProfile.flag_terminos == null || 
                userProfile.flag_terminos.id == 2"
            :userProfile="userProfile"/>
        <div v-for="err in errMsg">
           <b-alert 
            v-model="errFlag" 
            variant="danger" 
            dismissible>
            {{err}}
          </b-alert> 
        </div>
        
        <div v-if="hasUserProfile">
          <h5>Tablero de indicadores</h5> 
          <b-row>
            <!-- Date of payment -->                                                              
            <b-col lg="3">
              Fecha inicio *
              <b-form-group>
                <CInput                                                                                                    
                  type="date"
                  placeholder="dd/mm/yyyy"   
                  v-model="dateStart"/>                                                      
              </b-form-group>                                                                
            </b-col> 
            <b-col lg="3">
              Fecha fin *
              <b-form-group>
                <CInput                                                                                                    
                  type="date"
                  placeholder="dd/mm/yyyy"   
                  v-model="dateFinish"/>                                                      
              </b-form-group>                                                                
            </b-col> 
            <b-col lg="3">
              <b-button @click="submit">
                Consultar
              </b-button>
            </b-col>
          </b-row> 
          <b-row v-if="!loading">
            <b-col sm="6" lg="3">              
              <CWidgetIcon
                :header="services ? services.length+'' : '0'"
                text="Servicios asignados"
                color="gradient-info"
                >
                <CIcon name="cil-cloud-download" width="24"/>
                <!-- <template #footer>
                  <CCardFooter class="px-3 py-2">
                    <CLink
                        class="font-weight-bold font-xs text-muted d-flex justify-content-between"                        
                        target="_blank"
                        @click="goTo('petsIndex')"
                    >
                        Abrir detalle
                        <CIcon name="cil-arrow-right" width="16"/>
                    </CLink>
                  </CCardFooter>
                </template> -->
              </CWidgetIcon>            
            </b-col>
            <b-col sm="6" lg="3">              
              <CWidgetIcon
                :header="servicesCompleted"
                text="Servicios completados"
                color="gradient-primary"
                >
                <CIcon name="cil-check" width="24"/>
                <!-- <template #footer>
                  <CCardFooter class="px-3 py-2">
                    <CLink
                        class="font-weight-bold font-xs text-muted d-flex justify-content-between"                        
                        target="_blank"
                        @click="goTo('petsIndex')"
                    >
                        Abrir detalle
                        <CIcon name="cil-arrow-right" width="16"/>
                    </CLink>
                  </CCardFooter>
                </template> -->
              </CWidgetIcon>            
            </b-col>
            <!-- <b-col sm="6" lg="3">              
              <CWidgetIcon
                :header="totalEarnings"
                text="Total ganancias "
                color="gradient-success"
                >
                <CIcon name="cil-money" width="24"/>
                <template #footer>
                  <CCardFooter class="px-3 py-2">
                    <CLink
                        class="font-weight-bold font-xs text-muted d-flex justify-content-between"                        
                        target="_blank"
                        @click="goTo('petsIndex')"
                    >
                        Abrir detalle
                        <CIcon name="cil-arrow-right" width="16"/>
                    </CLink>
                  </CCardFooter>
                </template>
              </CWidgetIcon>            
            </b-col>              -->
          </b-row>     
          <b-row v-if="items.length > 0">
            <b-table
              :items="items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="md"
              show-empty
              small
              @filtered="onFiltered"
              :tbody-tr-class="rowClass">
              <template #cell(name)="row">
                {{ row.value }}
                <!-- {{ row.value.first }} {{ row.value.last }} -->
              </template>

              <template #cell(actions)="row">
                <b-button 
                  size="sm" 
                  @click="openServicesTable(row.item, row.index, $event.target)" 
                  class="mr-1">
                    Abrir detalle
                </b-button>
                <b-button
                  size="sm"
                  @click="row.toggleDetails"
                >{{ row.detailsShowing ? 'ocultar' : 'más' }} información</b-button>
              </template>

              <template #row-details="row">
                <b-card>                    
                  <b-row>                                                                 
                    <b-col lg="12" class="badge-props"> 
                      <div>
                        <CBadge color="success" class="float-left">
                          Servicios completados: {{ row.item[34] }} 
                        </CBadge>                                                  
                      </div>                        
                    </b-col>
                    <b-col lg="12" class="badge-props"> 
                      <div>
                        <CBadge color="danger" class="float-left">
                          Servicios incompletos - sin finalizar: {{ row.item[2] }} 
                        </CBadge>                       
                      </div>                        
                    </b-col>
                    <b-col lg="12" class="badge-props"> 
                      <div>
                        <CBadge color="warning" class="float-left">Servicios sin ejecutar - no iniciados: {{ row.item[1] }} </CBadge>                       
                      </div>                        
                    </b-col>
                    
                    <!-- <b-col lg="12"> 
                      <div>
                        <strong>Servicios sin ejecutar:</strong>
                        {{ row.item[1] }}
                      </div>                        
                    </b-col> -->
                    <b-col lg="12"> 
                      <div>
                        <strong>Servicios cancelados por lluvia:</strong>
                        {{ row.item[67] }}
                      </div>                        
                    </b-col>
                    <b-col lg="12"> 
                      <div>
                        <strong>Servicios de cancelación genérica:</strong>
                        {{ row.item[68] }}
                      </div>                        
                    </b-col>
                  </b-row>
                </b-card>
              </template>
            </b-table>
          </b-row>  

          <!-- Table of walker services -->
          <!-- <WalkerServicesDetailModal
            :flagServicesDetail="flagServicesDetail"
            :services="services"
            @flagServicesDetail="flagServicesDetail=$event"
            v-if="services!= null && services.length > 0"
          />  -->
          
        </div>


        <!-- if user has not profile -->
        <div v-else>
            <b-row>
                <b-col md="5">
                <h4 class="display-4">¡Bienvenido!</h4>
                <p class="lead">{{ $t('phrases.msg_provider_intro') }}</p>
                <p class="">{{ $t('phrases.msg_provider_intro_b') }}</p>
                <!-- <img id="joinSession" class="" src="@/assets/images/zoom-icon.svg" width="65" height="65" alt="No Cam"/>-->
                <hr clas="my-4"/>
                 <b-button 
                    class="mr-1 s-bg s-bg-text"
                    style="width:100%; margin-bottom:1rem;"
                    @click.prevent="visitCreateProfile"
                    >
                    <strong>Crea tu perfil ahora</strong>      
                </b-button> 
                
                </b-col>
                <b-col md="7">
                <div>                   
                    <div>
                      <b-card
                        overlay
                        :img-src="serviceImage"
                        img-alt="Card Image"
                        text-variant="black"
                        title="Go Guau..."
                        >
                          Tu mejor amigo en manos amigas.
                      </b-card>
                    </div>
                </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import { required, minLength, email, requiredUnless, requiredIf } from 'vuelidate/lib/validators';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
// import WalkerServicesDetailModal from '../../modals/WalkerServicesDetail'
import TermsAcceptance from '../../modals/TermsAcceptance'
export default {
    name: 'DashboardUser',
    components: {
      Loading,
      TermsAcceptance,
      // WalkerServicesDetailModal,
    },
    data () {
      return {
        loading: true,
        userProfile: null,
        userCurrency: null,
        hasUserProfile: false,
        userId: null,
        userDbId: null,
        serviceImage: 'https://storage.googleapis.com/test-goguau.appspot.com/resources/paseo2-min.jpeg',
        // userProfileInfo
        petsLength: 0+'',
        tagsLength: 0,
        dateStart: null,
        dateFinish: null,
        err: false,
        errMsg: [],
        services: null, 
        //table data
        items: [],
        fields: [
          { key: "name", label: "Nombre" },
          { key: "numberServices", label: "# servicios", sortable: true, sortDirection: "desc" },
          { key: "servicesToPay", label: "Servicios completados", sortable: true, sortDirection: "desc" },
          // { key: "earning", label: "Ganancia", sortable: true, sortDirection: "desc" },             
          { key: "actions", label: "Acciones" }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],          
      }
    },
    validations: {
      dateStart: {
        required
      },
      dateFinish: {
        required
      }
    },
    created () {
      
        this.userId = this.$store.getters.userId;
        let userProfile = JSON.parse(this.$store.getters.userProfile);              
console.log('dashboard-providerProfile: ', userProfile)
        let date = new Date()
        this.dateStart =moment(date).format("yyyy-MM-DD")
        this.dateFinish =moment(date).format("yyyy-MM-DD")                
        // this.$store.commit('toggle', 'darkMode')        
        if (userProfile){            
            this.userProfile = userProfile
            this.userDbId = this.userProfile.id;                       
            this.fetchProfileInfo()
            this.hasUserProfile = true
        };

        // console.log('dashboard-userId: ', this.userId )
        // console.log('dashboard-userDbId: ', this.userDbId)
        

        this.loading = false;
    },
    computed: {
      servicesCompleted:{
        get() {
          let servicesCompleted = 0
          if(this.items.length > 0) {
            this.items.map((item) => {
            servicesCompleted = servicesCompleted + item.servicesToPay
            });
          return servicesCompleted + ''
          } else {
            return ''
          }          
        },
        set(newValue) {
          return newValue
        }
      },
      // totalEarnings: {
      //   get(){
      //     let earnings = 0
      //     if(this.items.length > 0) {
      //       this.items.map((item) => {
      //         earnings = earnings + item.earning
      //       });
      //     return earnings +''
      //     } else {
      //       return ''
      //     } 
      //   },
      //   set(newValue){
      //     return newValue
      //   }
      // }
    },
    methods: {
      visitCreateProfile: function(){
        this.$router.replace('/providers/users/create')
      },
      fetchProfileInfo () {
        // console.log('fetchProfileInfo')
        // console.log(this.userProfile)
        if('pets' in this.userProfile){
          let pets = this.userProfile.pets
          this.petsLength = pets.length+''
          const tags = pets.filter(pet => pet.tag == 'Si')
          this.tagsLength = tags.length + ''                    
        }
      },
      goTo(to) {
        if (to == 'buyTag') {
          this.$router.replace({path: '/services/profile/3'})        
        } else if (to == 'petsIndex') {
          this.$router.replace({path: '/pets/index'})
        }
      },
      submit(){        
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.errFlag = true
          this.errMsg.push('Debes seleccionar un rango de fechas')
        } else {
          this.errMsg = []
          this.fetchKPIs()
        }
      },
      fetchKPIs () {
        this.loading = true
        let data = {
          email: this.userProfile.email,
          dateStart: this.dateStart + "T00:00:00.000Z",
          dateFinish: this.dateFinish + "T23:59:59.000Z"
        }
        
        let fetchKPIs = this.$firebase.functions().httpsCallable('getWalkerKPIs');
        fetchKPIs(data).then((response) => {          
          this.items = []
          let data = response.data.data;
          this.services = data.data
          // console.log('services')
          // console.log(this.services)
          if(this.services.length == 0){
            this.errMsg.push('No tienes servicios asociados para este rango de fechas.')
            this.errFlag = true
          }

          let names = [];
          //to identify the services by type: Paseo, Adiestramiento, Sin recibo transacción
          this.services.map((service) => {
            let articulo_id = ''
            if(service.pedido != null){
              articulo_id = service.pedido.articulo_id;
            } else {              
              service.pedido = {
                articulo_id: 'Sin recibo transacción'
              } 
              articulo_id = 'Sin recibo transacción'
            }                        
            const nameFound = names.find(name => name == articulo_id)            
            if (nameFound == undefined) {
              names.push(articulo_id)
            }
          });
          // console.log(names)
          
          //to split the services type by status: programado, inicia paseo ...
          names.map((name) => {                        
            // console.log(name)            
            let servicesFiltered = this.services.filter((service) => service.pedido.articulo_id  == name)
            // console.log(servicesFiltered)

            let item = {
              name,
              numberServices: servicesFiltered.length,
            }

            let codes = [
              {id:1, name: 'Programado'},
              {id:2, name: 'Iniciar paseo'},
              {id:34, name: 'Finalizar paseo'},
              {id:67, name: 'Cancelado por lluvia'},
              {id:68, name: 'Cancelado genéricamente'},             
              ];
            let earning = 0            
            let counter = 0

            codes.map((code) => {              
              let ServicesByStatusFiltered = servicesFiltered.filter((service) => service.estado_servicio.id == code.id)           
              item[code.id] = ServicesByStatusFiltered.length
              
              //34 Finalizar paseo
              if(code.id == 34){                               
                ServicesByStatusFiltered.map((item) => {                  
                  counter++
                  if(name == 'Paseo Grupal') {
                    earning = earning + Number(item.pedido.precio_und)*0.8                    
                  } else if (name == 'Adiestramiento Personalizado'){
                    earning = earning + Number(item.pedido.precio_und)*0.6                   
                  } else if (name == 'Paseo Personalizado'){
                    earning = earning + Number(item.pedido.precio_und)*0.8
                  }                  
                })
              }
              item['earning'] = earning;              
            });
            item['servicesToPay'] = counter;
            this.items.push(item)                        
          });
          // console.log(this.items)
          this.loading = false
          // console.log(data);
        }).catch((error) => {
          console.log(error);
        }).finally(() => {
          this.loading = false;
        })
      },     
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      rowClass(item, type) {
        // console.log(item[2])
        if (!item || type !== 'row') return
        if (item[2] > 0) return 'table-danger'
        if (item.name == 'Sin recibo transacción') return 'table-info'
      },
      openServicesTable(item, index) {
        // console.log(item.name)
        let servicesByName = this.services.filter((service) => item.name == service.pedido.articulo_id)
        let servicesByNameString =  JSON.stringify(servicesByName) 
        this.$router.push({
          name: 'provider-services-table', 
          params: {services: servicesByNameString }
        })
      }
    }
}
</script>

<style scoped>
  .badge-props{
    margin-bottom: 0.5rem;
  }
</style> 